import React from "react";
import Content from '../views/BookDemo';

export default ({location}) => <Content location={location} seo={{
  title: 'Sign up for connected vehicle services',
  lang: 'en',
  description: 'Sign up for connected vehicle services. Choose one of the services: remote vehicle management, web-based fleet management or mobile application.',
  meta: [{
    name: 'keywords',
    content: 'Vehicle services'
  }]
}}/>